import React from "react";
import { navigate, useIntl, FormattedMessage } from "gatsby-plugin-intl";

import * as constants from "../../app.constant";
import AppLayout from "../../components/shared/AppLayout";
import successIcon from "../../assets/images/success-icon.png";

const PageReuploadDevicePhotoSuccess = () => {

    const intl = useIntl();
    const messages = intl.messages;

    const navigateBack = () => {
        navigate(constants.ROUTES.PLANS);
    };

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true}>
            <div className="app-page page-reupload-device-photo-success">

                <div className="reupload-device-photo-success">
                    <div className="container">
                        <div className="reupload-device-photo-success__header">
                            <h2 className="reupload-device-photo-success__status">
                                <img className="reupload-device-photo-success__status-icon img-fluid" src={successIcon} alt="" width="44" />
                                <span className="reupload-device-photo-success__status-text text-bold">
                                    <FormattedMessage id="reuploadDevicePhoto.success.headline" />
                                </span>
                            </h2>
                        </div>
                        <p className="reupload-device-photo-success__assistance-info">
                            <FormattedMessage id="reuploadDevicePhoto.success.description" />
                            <a href={`tel: ${messages["common.callCenter.number"]}`}>
                                <FormattedMessage id="common.callCenter.number" />
                            </a>
                        </p>
                        <p className="btn-container btn-container--center">
                            <button type="button" className="btn btn-lg btn-primary" onClick={navigateBack}>
                                <FormattedMessage id="reuploadDevicePhoto.success.cta" />
                            </button>
                        </p>
                    </div>
                </div>

            </div>
        </AppLayout>
    );
};

export default PageReuploadDevicePhotoSuccess;
